import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';

import { GET_REMOTE_PACS_DETAIL } from '../../../../queries/RemotePacs/RemotePacs';
import { networkErrorParse } from '../../../../utils/ErrorGraphQLUtils';
import RemotePacsView from '../../../../components/Hospital/Management/RemotePacs/RemotePacsView';
import { Layout } from '../../../../components/MenuLayout';
import { AlertUI, AlertWrapperUI } from '../../../../componentsUI/Alert';
import Loading from '../../../../components/Common/Loading';

export default ({ remotePacsUuid }) => {
  const { t } = useTranslation();
  const [serverError, setServerError] = useState(null);

  const { loading, error, data } = useQuery(
    GET_REMOTE_PACS_DETAIL,
    {
      variables: {
        uuid: remotePacsUuid,
      },
    },
  );

  const remotePacs = data && data.remotePacs;

  if (error && !serverError) {
    const parsedError = networkErrorParse(error);
    setServerError(parsedError && parsedError.replace(/:/g, '.'));
  }

  return (
    <Layout type="settings">
      {serverError && (
        <AlertWrapperUI>
          <AlertUI severity="error" title="Error">
            {t(serverError, { uuid: remotePacsUuid })}
          </AlertUI>
        </AlertWrapperUI>
      )}
      {loading && !remotePacs && !serverError && <Loading />}
      {remotePacs && <RemotePacsView remotePacs={remotePacs} />}
    </Layout>
  );
};
