import React, { forwardRef, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import { useMutation } from '@apollo/react-hooks';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
  UPDATE_REMOTE_PACS,
  GET_REMOTE_PACS_LIST,
  GET_REMOTE_PACS_DETAIL,
} from '../../../../../queries/RemotePacs/RemotePacs';
import { networkErrorParse } from '../../../../../utils/ErrorGraphQLUtils';
import { parseGraphqlErrors } from '../../../../../utils/FormikUtils';
import { RemotePacsForm } from '../RemotePacsForm';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles(() => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: 800,
      padding: 15,
      '@media (max-width:600px)': {
        margin: 5,
        padding: 10,
        '& > div': {
          paddingLeft: 5,
          paddingRight: 5,
        },
      },
    },
  },
  group: {
    padding: 8,
    '& .type': {
      marginBottom: 4,
    },
  },
  button: {
    margin: '0 10px',
    padding: '8px 25px',
    fontWeight: 700,
  },
}));

export const EditRemotePacsDialog = ({ open, onClose, remotePacs = {} }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const form = useRef();
  const hospital = useSelector((state) => state.hospital);
  const { uuid: remotePacsUuid } = remotePacs;
  const [serverError, setServerError] = useState(false);
  const [lockSubmit, setLockSubmit] = useState(false);
  const [TYPE_C_FIND, TYPE_C_MOVE] = [0, 1];

  const [updateRemotePacs, { loading }] = useMutation(UPDATE_REMOTE_PACS, {
    onCompleted() {
      onClose();
      setTimeout(setLockSubmit(false), 3500);
      toast(t('remote.pacs.updated.successfully'), { className: 'toast-info' });
    },
    onError(error) {
      setLockSubmit(false);
      setServerError({ level: 'error', message: networkErrorParse(error) || 'server.error' });
    },
    refetchQueries: [{
      query: GET_REMOTE_PACS_LIST,
      variables: {
        hospitalUuid: hospital.uuid,
      },
      fetchPolicy: 'cache-and-network',
    }, {
      query: GET_REMOTE_PACS_DETAIL,
      variables: {
        uuid: remotePacsUuid,
      },
      fetchPolicy: 'cache-and-network',
    }],
    awaitRefetchQueries: true,
  });

  const handleClose = () => {
    setServerError(false);
    onClose({ status: 'close' });
  };

  const handleSubmit = async (values, { setErrors }) => {
    setServerError(null);
    setLockSubmit(true);

    const cFindNodes = [{
      ip: values.cFindIp,
      port: values.cFindPort,
      aeTitle: values.cFindAeTitle,
      type: TYPE_C_FIND,
    }];

    const cMoveNodes = values.cMoveIp && values.cMovePort && values.cMoveAeTitle ? [{
      ip: values.cMoveIp,
      port: values.cMovePort,
      aeTitle: values.cMoveAeTitle,
      type: TYPE_C_MOVE,
    }] : [];

    if (values.cMoveIp || values.cMovePort || values.cMoveAeTitle) {
      ['cMoveIp', 'cMovePort', 'cMoveAeTitle'].forEach((field) => {
        if (!values[field]) {
          form.current.setFieldError(field, t('all.fields.are.required'));
          setLockSubmit(false);
        }
      });
      if (Object.keys(form.current.state.errors).length > 0) return;
    }

    try {
      const input = {
        uuid: remotePacsUuid,
        serverName: values.serverName,
        searchType: values.searchType,
        dicomNodes: [...cFindNodes, ...cMoveNodes],
      };
      await updateRemotePacs({ variables: { input } });
    } catch (errors) {
      const formikErrors = parseGraphqlErrors(errors.graphQLErrors, t);
      if (Object.keys(formikErrors).length === 0 && formikErrors.constructor === Object) {
        setServerError({ level: 'error', message: networkErrorParse(errors) });
      } else {
        setErrors(formikErrors);
      }
    }
  };

  const discardButton = { label: t('cancel'), action: handleClose };

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogTitle>{t('edit.remote.pacs.settings')}</DialogTitle>
      <DialogContent>
        <RemotePacsForm
          form={form}
          remotePacs={remotePacs}
          hospital={hospital}
          submitLabel={t('update')}
          handleSubmit={handleSubmit}
          discardButton={discardButton}
          submitting={loading}
          lockSubmit={lockSubmit}
          serverError={serverError}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EditRemotePacsDialog;
