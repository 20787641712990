import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';

import { DeleteTwoTone, Edit, NavigateBefore } from '@material-ui/icons';

import { Navbar } from '../../../Navbar/styled/NavbarStyles';
import { SectionBar } from '../../../../componentsUI/SectionBar';
import { AlertUI, AlertWrapperUI } from '../../../../componentsUI/Alert';
import { RemotePacsDataCard } from './RemotePacsDataCard';
import { DeleteRemotePacsDialog } from './modal/DeleteRemotePacsDialog';
import Can from '../../../../casl/Can';
import EditRemotePacsDialog from './modal/EditRemotePacsDialog';

export default ({ remotePacs }) => {
  const { t } = useTranslation();
  const [disableButtons, setDisableButtons] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const hospital = useSelector((state) => state.hospital);

  const handleViewList = () => navigate('/hospital/settings/remote-pacs');
  const handleOpenEdit = () => setShowEditModal(true);
  const handleOpenDelete = () => setShowDeleteModal(true);
  const handleCloseEdit = () => setShowEditModal(false);
  const handleCloseDelete = ({ status }) => {
    setShowDeleteModal(false);
    if (status === 'deleted') {
      setDisableButtons(true);
      setTimeout(() => {
        setDisableButtons(false);
        handleViewList();
      }, 3500);
    }
  };

  const subtitle = t('remote.pacs.settings');
  const buttons = [
    { name: 'go.back.to.list', icon: NavigateBefore, handleClick: handleViewList, disabled: false },
    { name: 'divider2', type: 'divider' },
    { name: 'edit.pacs.settings', icon: Edit, I: 'delete', data: hospital, handleClick: handleOpenEdit, disabled: disableButtons },
    { name: 'divider2', type: 'divider' },
    { name: 'remove.pacs', icon: DeleteTwoTone, I: 'delete', data: hospital, handleClick: handleOpenDelete, disabled: disableButtons },
  ];

  return (
    <>
      <Navbar>
        <SectionBar
          title="hospital.settings.remote.pacs"
          subtitle={subtitle}
          items={buttons}
        />
      </Navbar>
      {remotePacs ? (
        <>
          <DeleteRemotePacsDialog
            open={!!showDeleteModal}
            onClose={handleCloseDelete}
            remotePacs={remotePacs}
          />
          <EditRemotePacsDialog
            open={!!showEditModal}
            onClose={handleCloseEdit}
            remotePacs={remotePacs}
          />
          {hospital ? (
            <Can I="manage" this={hospital}>
              <RemotePacsDataCard remotePacs={remotePacs} />
            </Can>
          ) : (
            <AlertWrapperUI>
              <AlertUI severity="warning" title={t('non.authorized')}>
                {t('you.are.not.allowed.to.change.these.settings')}
              </AlertUI>
            </AlertWrapperUI>
          )}
        </>
      ) : (
        <AlertWrapperUI>
          <AlertUI severity="warning" title={t('not.found')}>
            {t('no.results.have.been.found')}
          </AlertUI>
        </AlertWrapperUI>
      )}
    </>
  );
};
