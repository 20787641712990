import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ScrollableContainer } from '../../../Common/ScrollableContainer';
import Loading from '../../../Common/Loading';

import useStyles from '../../Edit/styled/HospitalFormMakeStyles';
import { DicomNodesCard } from '../../../Common/DicomNodes/DicomNodesCard';
import { remotePacsSearchType } from '../../../../utils/RemotePacsUtils';

const useLocalStyles = makeStyles((theme) => ({
  data: {
    '& .subheading': {
      fontSize: '1.375rem',
    },
    '& .value': {
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
    '& .type': {
      fontWeight: 700,
      color: theme.palette.grey.A400,
    },
    '& .subtitle': {
      fontSize: '1.125rem',
      color: theme.palette.grey.A400,
    },
  },
}));

export const RemotePacsDataCard = ({ remotePacs }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const styles = useLocalStyles();
  const searchType = remotePacsSearchType.find((type) => type.value === `${remotePacs.searchType}`);

  return (
    <Box className={classes.container}>
      <ScrollableContainer padding="1.25em 0 0">
        <Paper elevation={2} className={classes.wrapper}>
          <Grid container spacing={2}>
            {!remotePacs && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Loading />
                </Grid>
              </Grid>
            )}
            {!!remotePacs && (
              <>
                <Grid container spacing={2} className={styles.data}>
                  <Grid item xs={12}>
                    <Typography component="span" className="value">
                      {remotePacs.serverName}
                    </Typography>
                    <Typography component="div" className="subtitle">
                      {t('remote.pacs')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography component="span" className="type">
                      {t('settings')}
                    </Typography>
                    <Typography component="div" className="subtitle">
                      {searchType && (
                        <>
                          {`${t('search.type')}: ${t(searchType.label)}`}
                          <br />
                        </>
                      )}
                      {t('dicom.nodes.parameters')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} />
                </Grid>
                <DicomNodesCard remotePacs={remotePacs} echoTest />
              </>
            )}
          </Grid>
        </Paper>
      </ScrollableContainer>
    </Box>
  );
};
